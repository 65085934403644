/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 14:58:21
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-16 14:34:54
 */

// import { parseTime } from '@/plugins/lib/modules/date'
// import { remit, getType } from '@/utils/options'

export var searchOption = function searchOption(ctx) {return [
    // {
    //   kname: 'roleName',
    //   label: '角色名称：',
    //   component: {
    //     name: 'el-input',
    //     props: {
    //       clearable: true,
    //       placeholder: '请输入角色名称'
    //     }
    //   }
    // }
  ];};

export var columns = function columns(ctx) {return [
  {
    label: '序号',
    type: 'index',
    "with": 100 },

  {
    label: '角色',
    kname: 'roleName' },

  {
    label: '创建时间',
    kname: 'createAt' },

  {
    label: '状态',
    kname: 'status',
    component: {
      name: 'c-switch',
      props: {
        disabled: function disabled(v) {
          // return v.isChange === 1 || !ctx.hasPermission([52])
          return v.isChange === 1;
        } },

      events: {
        change: ctx.changeStatus } },


    width: 120 },

  {
    label: '操作',
    kname: 'btns',
    width: 120 }];};